<template>
  <v-app>
    <v-navigation-drawer
      v-if="
        !(
          $route.path.includes('dashboard/video') ||
          $route.path.includes('conference/initiator')
        )
      "
      :mini-variant="mini"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            :key="item.text"
            active-class="activeMenu"
            v-else-if="
              (item.text == 'Video Session' &&
                ($route.path.includes('dashboard/video') ||
                  $route.path.includes('conference/initiator'))) ||
              item.text != 'Video Session'
            "
            :disabled="disableAll"
            :class="$route.path.includes(item.path) ? 'activeMenu' : ''"
            @click="$router.push({ path: item.path })"
          >
            <v-list-item-action>
              <v-icon
                :color="$route.path.includes(item.path) ? 'app_bar_icon' : ''"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="
        !(
          $route.path.includes('dashboard/video') ||
          $route.path.includes('conference/initiator')
        )
      "
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="app_bar_color"
      dark
    >
      <v-app-bar-nav-icon @click.stop="toogleSidebar()" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <img height="30px;" :src="logoImageUrl" />
      </v-toolbar-title>
      <v-spacer />
      <v-switch
        value
        class="ma-0 mr-4"
        style="margin-top: 23px !important"
        color="success"
        v-if="!isVideoConference"
        v-model="online"
        @change="toggleOnline(online ? 'on' : 'off', true, 'toggle button')"
        :label="online ? 'Go Offline' : 'Go Online'"
        :loading="toggleDisabled"
        :disabled="toggleDisabled || disableAll"
      ></v-switch>
      <!-- <v-btn
        class="ma-2 ml-4"
        depressed
        color="app_bar_color"
        style="cursor: default"
        @click="getMySpeed()"
      >
        <v-icon left style="font-size: 22px">fa fa-tachometer-alt</v-icon>
        <p class="ma-0 pa-0" style="text-transform: none">{{ internetSpeed && internetSpeed.speedMbps ? internetSpeed.speedMbps : 0  }} Mbps</p>
        <v-icon right style="font-size: 16px">fas fa-sync</v-icon>
      </v-btn> -->
      <!-- <p
        class="ma-0"
        v-if="internetSpeed && internetSpeed.speedMbps ? parseFloat(internetSpeed.speedMbps) < 20 : internetSpeed && internetSpeed.status === 'failed'"
        style="color: #C8D15F; font-size: 13px"
      > <i class="fas fa-exclamation-circle mr-2"></i>{{internetSpeed && internetSpeed.status === "failed" ? "Error in getting speed" : "Internet too slow"}} </p> -->
      <v-spacer />
      <v-btn
        depressed
        v-if="isVideoConference && !isMobile"
        color="app_bar_color"
        :disabled="disableAll"
        @click="stopVideoConference()"
      >
        Dashboard
        <v-icon class="ml-2">fas fa-pager</v-icon>
      </v-btn>
      <v-btn
        depressed
        v-if="!isVideoConference && !isMobile"
        color="app_bar_color"
        :disabled="disableAll"
        @click="startVideoConference()"
      >
        Video Conference
        <v-icon class="ml-2">fas fa-handshake</v-icon>
      </v-btn>
      <!--logged in RE name-->
      <div style="display:contents;">
        <div>
          <img src="@/assets/loginUser.svg" style="vertical-align:middle;" />
        </div>
        <div style="margin-left:5px;">
          <div style="line-height:15px;font-size:18px;font-weight:bold;" :title='this.agentData.name'>
            {{this.agentData && this.agentData.name && this.agentData.name.length >= 15 ? this.agentData.name.substring(0,15) + '...' : this.agentData.name }}
          </div>
          <div style="line-height:15px;" :title='this.agentData.username'>
            {{this.agentData && this.agentData.username && this.agentData.username.length >= 20 ? this.agentData.username.substring(0,20) + '...' : this.agentData.username}}
          </div>
        </div>
      </div>
      <!--logged in RE name-->
      <v-btn depressed color="app_bar_color" @click="logoutDialog = true">
        Logout
        <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <div v-if="$route.path.includes('dashboard/history')"
    style="margin-top: 70px; margin-left: 280px;display:flex">

     <div style="line-height: 68px;">Select your call preferences</div>
     <div style="margin-left:15px; max-width: 250px;">
         <v-select v-model="currentSkill" :items=assignedSkills :label="getSkillLabel()"></v-select>
     </div>
     <div>
         <v-btn depressed :disabled="assignedSkills.length>0 ? false: true" color='primary' style="margin-top:15px; margin-left: 20px; text-transform: none;"
             @click="updateSkill(currentSkill)">Update</v-btn>
     </div>
 </div>
    <router-view :checkInterval="internetSpeedCheckTimer"  :socket='socket'/>
    <v-dialog v-model="showCallComing" persistent max-width="500">
      <v-card>
        <v-card-title class="title">Incoming Call</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12" cols="12" md="4" xl="4" lg="4" class="pa-2">
              <img :src="incomingCallData.image" style="width: 100%" />
            </v-col>
            <v-col sm="12" cols="12" md="8" xl="8" lg="8" pa-2 class="pa-2">
              <p class="subheading">Name: {{ incomingCallData.name }}</p>
              <div v-if="incomingCallData.customCallData && incomingCallData.customCallData.length > 0">
                  <p class="subheading" v-for="(data, key) in incomingCallData.customCallData" :key="key">{{ Object.keys(data)[0] }}: {{ Object.values(data)[0] }}</p>
              </div>
              <p class="subheading" v-if="incomingCallData.applicationId">Application ID: {{ incomingCallData.applicationId }}</p>
              <p class="subheading" v-if="incomingCallData.phone">Mobile No.: {{ incomingCallData.phone }}</p>
              <p class="subheading">Email: {{ incomingCallData.email }}</p>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn
              color="green"
              depressed
              block
              dark
              @click="
                openNewTab(
                  incomingCallData.url,
                  incomingCallData.id,
                  incomingCallData.direct
                )
              "
              >Join</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="askPhoneNumber" persistent max-width="500">
      <v-card>
        <v-card-title class="title mb-3"
          >Please add your mobile number</v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Mobile phone number"
            v-model="phone"
            type="text"
            counter
            :rules="phoneRules"
            maxlength="10"
            outlined
          ></v-text-field>
          <div class="text-right">
            <v-btn
              color="red darken-1"
              text
              @click="
                askPhoneNumber = false;
                phone = '';
              "
              >Cancel</v-btn
            >
            <v-btn color="green darken-1" text @click="updatePhone()"
              >Update</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog
        v-model="logoutDialog"
        persistent
        width="35%"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Are you sure, you want to logout?
          </v-card-title>
          <br>
          <v-card-actions style="margin-top: -1rem; padding-bottom: 1rem">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="logoutDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              depressed
              color="error"
              @click="logout()"
            >
              Logout
              <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-navigation-drawer
      permanent
      absolute
      right
      class="speedDrawer"
      v-if="
        (
          $route.path.includes('dashboard/history')
        )
      "
    >

    <div id="speedbar" v-if="hideSpeedTest">
      <h3 id="speedbarTitle">Network Speed & Quality Test</h3>
      <v-btn
        depressed
        id="retrybtn"
        @click="getMySpeed"
      >
        <p class="ma-0 pr-0">{{ `Refreshes every ${speedCheckInterval} minutes`}}<img src="@/assets/Vectorrefresh.svg" class="pl-2 ma-0"></p>
      </v-btn>
      <div class="mt-3 pt-3" v-for="(value,key) in speedProps" :key="key">
        <p :class="speedProps[key].status" id="speedbarSubtitle" class="ma-0"><img :src="getImgUrl(value)" class="pr-2 ma-0">{{ key }}</p>
        <p v-if="!online" :class="speedProps[key].status" style="font-size: 32px; font-weight: 500"> - </p>
        <img v-else-if="speedLoading" src="@/assets/Vectorloading.svg" class="mt-5">
        <p v-else id="speedbarValue" :class="speedProps[key].status" class= "ma-0">{{ value.value }}</p>
        <p v-if="styleProps[speedProps[key].status][key]" id="speedbarMsg" :class="speedProps[key].status" class="ma-0">{{ styleProps[speedProps[key].status][key] }}</p>
      </div>
      <div v-if="speedTestFailed && online">
        <p class="mt-5" id="speedbarMsg">{{ styleProps.failed.msg }}</p>
      </div>
    </div>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { sideBar, end_points, base_url } from "./../../config";
import { rightClickBlock, disableInspect } from "./../../Plugins/utils";
import { downloadFile } from "@/flux/persist.flux";
import { calculateSpeed } from "@/Plugins/internetSpeed.js";
import io from "socket.io-client";
import { reAgent } from '@/assets/subStatus.json';
import {logReportData}  from  "@/Plugins/reportLogger.js";

let offset = (new Date().getTimezoneOffset() / 60) * -1;
let axiosInstance = window.axiosInstance;
let store = window.store;
let eventBus = window.eventBus;
export default {
  props: {
    source: String,
  },
  data: () => ({
    isMissedCall: false,
    isCallNotPicked: false,
    dialog: false,
    isVideoConference: false,
    drawer: null,
    items: sideBar.dashboard,
    online: true,
    mini: false,
    keepREOnline: false,
    showCallComing: false,
    incomingCallData: {},
    toggleDisabled: false,
    disableAll: false,
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    isVideoKYC: false,
    isVideoConferenceGrant: false,
    isScreenshare: false,
    agentData: {},
    phone: "",
    idleTime: 0,
    idleTimeout: 10, //in mins
    fetchCallErrorCount: 0,
    idleInterval: {},
    idleTimeForReLogoutLogging: 0,
    idleTimeoutForReLogoutLogging: 90, // in minutes, this variable is use to force logout after defined minutes.
    intervalTime: 5, // in minutes, this variable is use to log RE logout time in defined interval of minutes.
    logoutEmmitingInterval: {},
    adminCallTimeout: {},
    internetSpeed: {
      speedMbps: "0",
    },
    hideSpeedTest:false,//temp blocking speed test as it is cloaking
    socket :undefined,
    speedCheckInterval: 5, // default value
    internetSpeedCheckTimer: null,
    logoImageUrl: undefined,
    assignedSkills:[],
    currentSkill:"",
    phoneRules: [
      (v) => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a proper mobile number!",
    ],
    askPhoneNumber: false,
    speedProps : {
      Strength: { icon : "Vectorstrength.svg", status : 'loading'},
      // Connect: {icon: 'Vectorconnect.svg',status : 'loading'},
      Ping : {icon: 'Vectorping.svg', status : 'loading'},
      Jitter: {icon: 'Vectorjitter.svg', status : 'loading'},
      Download: {icon: 'Vectordownload.svg', status : 'loading'},
      Upload: {icon: 'Vectorupload.svg', status : 'loading'}
    },
    styleProps : {
      poor : {
        filter : "filter: invert(52%) sepia(100%) saturate(5972%) hue-rotate(347deg) brightness(105%) contrast(99%);",
        Download : "Recommended download speed is 20 Mbps. The video recording may fail! Ensure there is no other application consuming bandwidth.",
        Upload : "Recommended upload speed is 20 Mbps. The video recording may fail! Ensure there is no other application consuming bandwidth.",
        Ping : "The ping is too high! It’s recommended to have ping less than 50ms. The video recording may stop or screen captures may fail.",
        Jitter : "The Jitter is too high! It’s recommended to have Jitter less than 20ms. The video call may fail.",
        Strength : "Network is poor"
      },
      average: {
        Download : "Recommended download speed is 20 Mbps. Ensure there is no other application consuming bandwidth.",
        Upload : "Recommended upload speed is 20 Mbps. Ensure there is no other application consuming bandwidth.",
        Ping : "It’s recommended to have ping less than 50ms. The video recording may stop or screen captures may fail.",
        Jitter : "It’s recommended to have Jitter less than 20ms. The video call may lag.",
        Strength : "Network is average"
      },
      good : {},
      loading : {},
      offline : {},
      failed : {
        msg : "Unable to load speed test values. Anyhow, you can proceed with VKYC.Kindly contact support if this issue persists."
      },
    },
    logObject: reAgent.videoPage,
    speedLoading: false,
    isRingToneRequired : false,
    speedTestFailed: false,
    logoutDialog: false,
    renameSkillLabel: "",
  }),
  methods: {
    getImgUrl(value) {
      return require('../../assets/' + value.icon);
    },
    // checking the speedData and updating the cosmetics
    changeStyle(speedData) {
      try {

        if(!this.online) {
          for(let key in this.speedProps) {
             this.speedProps[key].status = "offline";
          }
        } else if(this.speedLoading) {
           for(let key in this.speedProps) {
              this.speedProps[key].status = "loading";
           }
        } else if(speedData && speedData.status == 'failed') {
            for(let key in this.speedProps) {
              this.speedProps[key].status = "offline";
              if(key == 'Download' || key == 'Upload') {
                this.speedProps[key].value = "0 Mbps";
              } else {
                this.speedProps[key].value = "0 ms";
              }
            }
            this.speedProps['Strength'].status = 'offline';
            this.speedProps['Strength'].value = 'Failed';
        } else {
            let poor = 0, average = 0;
            for(let key in this.speedProps) {
              if(key == 'Download' || key == 'Upload') {
                this.speedProps[key].value = speedData[key] ? speedData[key] + " Mbps" : "0 Mbps";
                if(speedData[key] < 10) {
                  poor = poor + 1;
                  this.speedProps[key].status = 'poor';
                } else if(speedData[key] < 20 && speedData[key] >= 10) {
                  average = average + 1;
                  this.speedProps[key].status = 'average';
                } else {
                  this.speedProps[key].status = 'good';
                }
              } else if(key == 'Ping') {
                this.speedProps[key].value = speedData[key] ? speedData[key] + " ms" : "0 ms";
                if(speedData[key] >= 80) {
                  poor = poor + 1;
                  this.speedProps[key].status = 'poor';
                } else if(speedData[key] < 80 && speedData[key] >= 50) {
                  average = average + 1;
                  this.speedProps[key].status = 'average';
                } else {
                  this.speedProps[key].status = 'good';
                }
              } else if(key == 'Jitter') {
                this.speedProps[key].value = speedData[key] ? speedData[key] + " ms" : "0 ms";
                if(speedData[key] >= 100) {
                  poor = poor + 1;
                  this.speedProps[key].status = 'poor';
                } else if(speedData[key] < 100 && speedData[key] >= 20) {
                  average = average + 1;
                  this.speedProps[key].status = 'average';
                } else {
                  this.speedProps[key].status = 'good';
                }
              } else {
                this.speedProps[key].value = "-";
              }
            }

            if(poor > 0) {
              this.speedProps['Strength'].status = 'poor';
              this.speedProps['Strength'].value = 'Poor';
            } else if(average > 0) {
              this.speedProps['Strength'].status = 'average';
              this.speedProps['Strength'].value = 'Average';
            } else {
              this.speedProps['Strength'].status = 'good';
              this.speedProps['Strength'].value = 'Good';
            }
        }
      } catch(err) {
        console.log(err);
      }
    },
    async getMySpeed() {
      if(true) { //disabled speed test as it is cloaking the network
        console.log("Speedtest already running...");
        return;
      }
      this.speedLoading = true;
      this.speedTestFailed = false;
      this.changeStyle();
      let selfIns = this;
      calculateSpeed('RE', (speedData) => {
          if(speedData && speedData.status == 'failed') {
            selfIns.speedLoading = false;
            selfIns.changeStyle(speedData);
            selfIns.speedTestFailed = true;
            return;
          }
          selfIns.speedLoading = false;
          selfIns.changeStyle(speedData);
          selfIns.internetSpeed = speedData;
      });
    },
    async getAgentData(userId) {
      this.agentData = (
        await axiosInstance.get(end_points.get_agent_data(userId))
      ).data;
      this.assignedSkills = this.agentData.assignedSkills ? this.agentData.assignedSkills:[];
      this.currentSkill = this.agentData.skillTag ? this.agentData.skillTag :"";
      const { username, email, name, phone, misData } = this.agentData;
      this.$store.commit("setAgentData", { username, email, name, phone, misData });
    },
    toogleSidebar() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.drawer = true;
        this.mini = !this.mini;
      } else {
        this.drawer = true;
        this.mini = false;
      }
    },
    timerIncrement() {
      this.idleTime = this.idleTime + 1;
      if ((this.idleTime > this.idleTimeout) && !this.online) {
        console.log("forcefully logout");
        window.clearInterval(this.idleInterval);
        axiosInstance.get(end_points.logout(sessionStorage.userId));
        eventBus.$emit("vueLoader", false);
        if (this.socket) {
          this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: sessionStorage.getItem("loggerId"), manualLogout: true});
          this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
          // this.socket.emit("deleteConsumer", this.$store.getters.userData);
          this.socket.disconnect();
        }
        if (
          this.$store.getters.userData.customerData.config?.global?.redirectOnLogout
        ) {
          let urlToSend = this.$store.getters.userData.customerData.config.global.redirectOnLogoutURL;
          store.flush();
          window.open(urlToSend, "_self");
        } else {
          store.flush();
          this.$router.push({
            path: "/",
          });
        }
      }
    },
    async timerIncreamentForReLogoutLogging() {
      this.idleTimeForReLogoutLogging += this.intervalTime; 
      if(this.idleTimeForReLogoutLogging >= this.idleTimeoutForReLogoutLogging){
        console.log("forcefully logout");
        if(this.logoutEmmitingInterval){
          window.clearInterval(this.logoutEmmitingInterval);
        }
        axiosInstance.get(end_points.logout(sessionStorage.userId));
        eventBus.$emit("vueLoader", false);
        if (this.socket) {
          this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: sessionStorage.getItem("loggerId"), manualLogout: true});
          this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
          // this.socket.emit("deleteConsumer", this.$store.getters.userData);
          this.socket.disconnect();
        }
        if (
          this.$store.getters.userData.customerData.config?.global?.redirectOnLogout
        ) {
          let urlToSend = this.$store.getters.userData.customerData.config.global.redirectOnLogoutURL;
          store.flush();
          window.open(urlToSend, "_self");
        } else {
          store.flush();
          this.$router.push({
            path: "/",
          });
        }
      } else {
        if (this.socket) {
          this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: sessionStorage.getItem("loggerId"), loggingForlogout: true});
          console.log("logged RE logout activity");
        } else {
          console.log("Socket instance not found");
        }
      }
    },
    async openNewTab(url, requestId, directCall) {
      clearInterval(this.internetSpeedCheckTimer);
      sessionStorage.setItem('inCall', true);
      this.showCallComing = false;
      this.isCallNotPicked = false;
      this.disableAll = true;
      let sessionId = url.split("/admin/")[1];
      window.clearTimeout(this.adminCallTimeout);
      window.clearInterval(this.idleInterval);
      this.$store.commit("setInitiated", true);
      let { customerData, userId } = this.$store.getters.userData;
      this.socket.emit("activityChecker", {customerData, userId, activity: "Picked Up a Call", loggerId: sessionStorage.getItem("loggerId")});

      // this.socket.emit("activityChecker", {...this.$store.getters.userData, activity: "Picked Up a Call", loggerId: sessionStorage.getItem("loggerId")});

      // let re_activity_res = await axiosInstance.post(
      //   end_points.re_activity,
      //   {...this.$store.getters.userData, activity: "Picked Up a Call", loggerId: sessionStorage.getItem("loggerId")}
      // );

      // if(re_activity_res.loggerId){
      //   eventBus.$emit("setLoggerId", re_activity_res.loggerId);
      // }

      if (directCall) {
        await axiosInstance.post(
          end_points.update_direct_call_status(requestId),
          {
            status: "ongoing",
          }
        );
      }
      this.$router.push({
        path: `video/${sessionId}/${requestId}`,
      });
    },
    handleOnlineToggleTimeout(){
      window.clearTimeout(this.toggleTimeout)
      this.toggleTimeout=setTimeout(() => {
        if(this.toggleDisabled)
        {
          eventBus.$emit("vueSnack", "Unable to go online at the moment. Please wait momentarily.");
          this.toggleDisabled=false;
          this.online=!this.online;
        }
      }, 10000);
    },
    //loggerFlag = true - send the status to report-worker
    //loggerFlag = false - means no need to send the status to report-worker
    //loggerFlag is added to differentiate when to send the status to report worker
    //In case of keepReOnline grant enabled, we are not sending status to report-worker when RE when setting RE as online aster the call
    async toggleOnline(status, loggerFlag, source) {
      /*
      status = "on" == online
      status = "off" == offline
      */
      this.toggleDisabled = true;
      const isGoingOffline = (status == 'off');
      const isGoingOnline = (status == 'on');
      
      // Update speedProps if going offline
      if (isGoingOffline) {
        for (let key in this.speedProps) {
          this.speedProps[key].status = "offline";
        }
      }
      
      try {
        this.online = isGoingOnline;
        store.setData("online", this.online);

        if (this.online) {
          console.log("Goes online!");
          this.handleOnlineToggleTimeout();
        } else {
          console.log("Goes offline!");
          // this.socket.disconnect();  // Uncomment if needed
        }

        await new Promise((resolve, reject) => {
          this.socket.emit("toggleStatus", { ...this.$store.getters.userData, online: this.online, source }, (response) => {
            if (response.error) {
              reject(new Error(response.error));
            } else {
              if(response.success) {
                if (loggerFlag) {
                  const event = this.online ? "onlineToggled" : "offlineToggled";
                  this.socket.emit(event, { ...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId") });
                }
                if(isGoingOnline) {
                  console.log("Get next end user called from toggle online fn");
                  this.getNextEndUser();
                }
              }
              resolve();
            }
          });
        });

      } catch (err) {
        // Revert status on error
        console.error("Error toggling status:", err.message);
        eventBus.$emit("vueSnack", `Something went wrong. Try going ${status}line again!`);
      } finally {
        this.toggleDisabled = false;
      }
    },
    startVideoConference() {
      this.isVideoConference = true;
      this.items = sideBar.conference;
      this.toggleOnline("off", true, 'startVideoConference fn call');

      this.$router.push({
        name: "newConference",
      });
    },
    stopVideoConference() {
      this.isVideoConference = false;
      this.items = sideBar.dashboard;
      this.$router.push({
        name: "dashboardHistory",
      });
      // Checking for autoOnline when coming back from video conference
      if (this.online || this.keepREOnline) {
        this.toggleOnline("on", true, 'stopVideoConference fn call');
      }
    },
    async logout() {
      eventBus.$emit("vueLoader",false);
      if (this.socket) {
        this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
        this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: sessionStorage.getItem("loggerId"), manualLogout: true});
        await this.toggleOnline("off", false, 'logout fn call');
          console.log("Aborted request by logout");
          axiosInstance.get(end_points.logout(sessionStorage.userId)); //logout

          sessionStorage.removeItem("loggerId");
          this.socket.disconnect();

          if (this.$store.getters.userData.customerData.config?.global?.redirectOnLogout) {
            let urlToSend = this.$store.getters.userData.customerData.config.global.redirectOnLogoutURL;
            store.flush();
            // window.open(urlToSend, "_self");
            window.location.replace(urlToSend);
          } else {
            store.flush();
            this.$router.replace({ path: "/" });
          }
      }
    },
    async updateSkill(skill){
      this.toggleOnline("off", true, 'update skill');
      this.socket.disconnect();
      this.agentData.skillTag = skill;
      try{
        let res = await axiosInstance.patch(
          end_points.get_agent_data(this.agentData.id),
          {
            skillTag:skill,
          }
        )
        eventBus.$emit('vueSnack','Updated Successfully')
      }catch(e){
        eventBus.$emit('vueSnack','Failed to Update')
      }
      this.socket.connect();
    },
    async updatePhone() {
      if (/^[6-9]{1}[0-9]{9}/.test(this.phone)) {
        let res = await axiosInstance.patch(
          end_points.get_agent_data(this.agentData.id),
          {
            phone: this.phone,
          }
        );
        if (res.data) {
          this.askPhoneNumber = false;
          eventBus.$emit("vueSnack", "Successfully updated phone number!");
        } else {
          eventBus.$emit("vueSnack", "Something went wrong!");
        }
      } else {
        eventBus.$emit("vueSnack", "Not a valid phone number!");
      }
    },
    async getImage(data) {
      if (
        data &&
        data.createSessionResp &&
        data.createSessionResp.result &&
        data.createSessionResp.result.users &&
        data.createSessionResp.result.users[0] &&
        data.createSessionResp.result.users[0].documents
      ) {
          let imgSrc, imageUrl;
          let documentArr = data.createSessionResp.result.users[0].documents;
          // Take the first occurance of a valid string to be used as image URL
          for (let i=0; i<documentArr.length; i++){
              imageUrl = documentArr[i].image ? documentArr[i].image: (documentArr[i].images || []).find(Boolean);
              if (imageUrl){ break }
          }
          /*
          let imageUrl = data.createSessionResp.result.users[0].documents[0].image ?
            data.createSessionResp.result.users[0].documents[0].image :
              data.createSessionResp.result.users[0].documents[0].images ?
                data.createSessionResp.result.users[0].documents[0].images[0] :
                  "" ;
          */
          if (imageUrl) {
            let imageDataResp = await downloadFile(imageUrl);
            if (imageDataResp.status === 200) {
              imgSrc = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`;
              return imgSrc;
            }
          } else {
            return ""
          }
      }
      return "";
    },
    getSkillLabel(){
      if(this.$store.getters.userData?.customerData?.config?.global?.renameSkillLabelRequired) {
        this.renameSkillLabel = this.$store.getters.userData?.customerData?.config?.global?.renameSkillLabel ?? "";
      }
      return this.renameSkillLabel?.toLowerCase() || 'skill';
    },
    async getNextEndUser() {
      this.socket.emit("getNextEndUser", this.$store.getters.userData.userId);
    },
    workOnSockets() {
      if (!store.getData("online")) {
        store.setData("online", true);
      }
      let userData = this.$store.getters.userData;
      this.online = this.keepREOnline || JSON.parse(store.getData("online"));
      let isIdleTimeNeeded = false;
      try {
        this.idleTimeout = this.$store.getters.userData.customerData.config?.global?.idleTime || 10;
      } catch (error) {
        console.log(error);
      }

      try {
        isIdleTimeNeeded = !!this.$store.getters.userData.customerData.config?.global?.idleTimeRequired;
      } catch (error) {
        console.log(error);
      }

      if (isIdleTimeNeeded) {
        this.idleInterval = setInterval(this.timerIncrement, 1000 * 60); // 1 minute
        let appHtml = document.getElementById("app");
        appHtml.addEventListener("mousemove", (e) => {
          this.idleTime = 0;
        });
        appHtml.addEventListener("keypress", (e) => {
          this.idleTime = 0;
        });
      }

      this.socket = io("/" , {query: 'role=re'});

      Notification.requestPermission(function (status) {
        console.log("Notification permission status:", status);
      });
      //setLogger event will be emitted to set the report-worker log id in the browser local storage
      this.socket.on("setLoggerId", (loggerId)=> {
        console.log("logger", loggerId);
        sessionStorage.setItem("loggerId", loggerId);
        if ( !this.online && !this.keepREOnline ) {
          this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
        }
      })

      // eventBus.$on("setLoggerId", (loggerId)=>{
      //   try{
      //     console.log("Event logger", loggerId);
      //     localStorage.setItem("loggerId", loggerId);
      //     if ( !this.online && !this.keepREOnline ) {
      //       this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
      //     }
      //   } catch (error){
      //     console.log("Error in loggerId event", error);
      //   }
      // });

      this.socket.on("connect", async () => {
        console.log("socket connected: ", this.socket.id);
        if(sessionStorage.getItem('refreshed')) {
          // Page was refreshed from Video.vue page
          console.log("Trying to check Refreshed status")
          try {
              let dataObj = JSON.parse(sessionStorage.getItem('refreshed'));
              let initiationId = dataObj.initiationId;
              let formData = { ...this.logObject.agentRefreshedThePage };
              formData.callId = dataObj.callId;
              formData.currentProcess = "Agent refreshed the page during a video call"; 
              logReportData(this.socket, initiationId, formData);
            } catch (logErr) {
              console.log(logErr);
            }
            sessionStorage.removeItem('refreshed')  
        }
        let userData = this.$store.getters.userData;
        console.log("LoggerId before emitting the offline event:::", sessionStorage.getItem("loggerId"));
        //Emitting offline event when the auto online grant is disabled and re is offline after the call
        if ( !this.online && !this.keepREOnline && sessionStorage.getItem("loggerId") ) {
          this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
        }

        await new Promise((resolve, reject) => {
          this.socket.emit("LoggedIn" , {...userData, loggerId: sessionStorage.getItem("loggerId")}, (response) => {
            if (response.error) {
              reject(new Error(response.error));
            } else {
              if (response.success) {
                if (this.online || this.keepREOnline) {
                  if(!sessionStorage.getItem("inCall")) {
                    this.toggleOnline("on", true, 'socket connect');
                  }
                  this.handleOnlineToggleTimeout();
                } else {
                  this.toggleDisabled = false;
                }
              }
              resolve();
            }
          });
        })
      });

      this.socket.on("broadcast", async (data) => {
        if (data && data.adminId === this.$store.getters.userData.userId) {
          if (
            data.event === "callCancelled" &&
            this.showCallComing &&
            data.callId === this.incomingCallData.id
          ) {
            if (this.showCallComing) {
              
              let { customerData, userId } = this.$store.getters.userData;
              this.socket.emit("activityChecker", {customerData, userId, activity: "Incoming Call Cancelled", loggerId: sessionStorage.getItem("loggerId")});

              // this.socket.emit("activityChecker", {...this.$store.getters.userData, activity: "Incoming Call Cancelled", loggerId: sessionStorage.getItem("loggerId")});

              // let re_activity_res = await axiosInstance.post(
              //   end_points.re_activity,
              //   {...this.$store.getters.userData, activity: "Incoming Call Cancelled", loggerId: sessionStorage.getItem("loggerId")}
              // );
              
              // if(re_activity_res.loggerId){
              //   eventBus.$emit("setLoggerId", re_activity_res.loggerId);
              // }
            }
            this.showCallComing = false;

            if(this.logoutEmmitingInterval){
              this.idleTimeForReLogoutLogging = 0;
              window.clearInterval(this.logoutEmmitingInterval);
              sessionStorage.removeItem('inCall');
              console.log("cleared logoutEmmitingInterval");
            }

            this.logoutEmmitingInterval = setInterval(this.timerIncreamentForReLogoutLogging, 60000 * this.intervalTime);
            console.log("set again logoutEmmitingInterval");

            if (this.keepREOnline) {
              //sending as false, as in case of KeeREOnline we dont have to log the status to report worker
              this.toggleOnline("on", true, 'socket broadcast');
            } else {
              this.toggleOnline("off", true, 'socket broadcast');
            }
            window.clearTimeout(this.adminCallTimeout);
          }
        }
      });

      this.socket.on("newCall", async (resp) => {
        if (
          resp &&
          resp.adminId === this.$store.getters.userData.userId &&
          this.online
        ) {
          let data = resp.payload;

          if (Notification.permission == "granted") {
            new Notification(
              `Hey, You got a new call from ${data.createSessionResp.result.users[0].name}!`
            );
          }

          console.log("new Call coming", data);
          //sending as false as when RE recives the call we don't updates RE as offline.
          await this.toggleOnline("off", true, 'new call');
          const processingSessionIds = JSON.parse(sessionStorage.getItem('processingSessionIds')) || [];
          sessionStorage.setItem('processingSessionIds', JSON.stringify([]));
          // reset idle time for logout if new call comes to RE.
          if(this.logoutEmmitingInterval){
            this.idleTimeForReLogoutLogging = 0;
            window.clearInterval(this.logoutEmmitingInterval);
            sessionStorage.removeItem('inCall');
            console.log("cleared logoutEmmitingInterval");
          }

          this.incomingCallData = {
            id: resp.callId,
            meetingCode: data.createSessionResp.result.meetingCode,
            url: data.createSessionResp.result.adminConfUrl,
            name: data.createSessionResp.result.users[0].name,
            image: await this.getImage(data),
            email: data.createSessionResp.result.users[0].email,
            customCallData: data.createSessionResp.result.users[0].customCallData || [],
            applicationId: data.createSessionResp.result.users[0].applicationId || "",
            phone:data.createSessionResp.result.users[0].phone,
            direct: resp.direct || false,
          };

          //Setting value for CAF number in Vuex store, if not present, setting it as the callId of the end user
          this.$store.commit("setCafNumber", (data.createSessionResp.result.users[0].cafNumber || resp.callId));

          if (this.incomingCallData.direct) {
            await axiosInstance.post(
              end_points.update_call_timestamp(this.incomingCallData.id),
              {
                directCallReceived: Date.now() + offset * 3600000,
              }
            );
          }

          this.showCallComing = true;
          this.isCallNotPicked = true;

          // Admin auto hangUp
          let adminCallTimeoutDuration =
            this.$store.getters.userData.customerData.config.introConf
              .adminCallTimeoutDuration || 40;
          this.adminCallTimeout = setTimeout(async () => {
            this.isCallNotPicked = false;
            this.isMissedCall = true;
            this.showCallComing = false;

            let { customerData, userId } = this.$store.getters.userData;
            this.socket.emit("activityChecker", {customerData, userId, activity: "Didn't Pick up the Call", loggerId: sessionStorage.getItem("loggerId")});

            // this.socket.emit("activityChecker", {...this.$store.getters.userData, activity: "Didn't Pick up the Call", loggerId: sessionStorage.getItem("loggerId")});
            
            // let re_activity_res = await axiosInstance.post(
            //   end_points.re_activity,
            //   {...this.$store.getters.userData, activity: "Didn't Pick up the Call", loggerId: sessionStorage.getItem("loggerId")}
            // );
            
            // if(re_activity_res.loggerId){
            //   eventBus.$emit("setLoggerId", re_activity_res.loggerId);
            // }

            // Checking for autoOnline when call is missed by timer end
            if (this.online || this.keepREOnline) {
              //based on keepReOnline sending the status to report worker
              this.toggleOnline("on", true, 'missed call');
            } else {
              this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
            }
            console.log('missed Call');

            sessionStorage.setItem('processingSessionIds', JSON.stringify(processingSessionIds));

            if(this.logoutEmmitingInterval){
              this.idleTimeForReLogoutLogging = 0;
              window.clearInterval(this.logoutEmmitingInterval);
              sessionStorage.removeItem('inCall');
              console.log("cleared logoutEmmitingInterval");
            }

            this.logoutEmmitingInterval = setInterval(this.timerIncreamentForReLogoutLogging, 60000 * this.intervalTime);
            console.log("set again logoutEmmitingInterval");

            // this.$router.go()
            eventBus.$emit('updateDashboardCalls', {});
            // console.log(,this.$root)
          }, adminCallTimeoutDuration * 1000);
        }
      });


    },
  },
  watch: {
    online: function() {
      this.changeStyle();
    }
  },
  beforeDestroy() {
    try {
      this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: sessionStorage.getItem("loggerId")});
      this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
      // this.socket.emit("deleteConsumer", {...this.$store.getters.userData});
      sessionStorage.removeItem("loggerId");
      this.socket.disconnect();
      this.socket.close();
      if(this.internetSpeedCheckTimer) {
        clearInterval(this.internetSpeedCheckTimer);
      }

      if(this.logoutEmmitingInterval){
        this.idleTimeForReLogoutLogging = 0;
        window.clearInterval(this.logoutEmmitingInterval);
        sessionStorage.removeItem('inCall');
        console.log("cleared logoutEmmitingInterval");
      }
      
      // to stop ringtone when call not being picked and user navigating to other page
      if(this.isRingToneRequired){
        if(this.$notificationSound){
          this.$notificationSound.pause();
        }
      }

    } catch (err) {
      console.log("Error in closing socket connection!!!");
    }
  },
  async mounted(){
    sessionStorage.getItem("RE_CALL_LOGGING_DONE") && sessionStorage.removeItem("RE_CALL_LOGGING_DONE");
  },
  async created() {
    try {
      if (sessionStorage.getItem('initiationId')) {
        sessionStorage.removeItem('initiationId')
      }
    } catch (err) {
      console.log("Could not delete previous session's initaitoan id", err);
    }

    if(this.logoutEmmitingInterval){
      window.clearInterval(this.logoutEmmitingInterval);
    }
    this.idleTimeForReLogoutLogging = 0;
    this.logoutEmmitingInterval = setInterval(this.timerIncreamentForReLogoutLogging, 60000 * this.intervalTime);
    console.log("set logoutEmmitingInterval");

    window.addEventListener("beforeunload", () => {
      this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: sessionStorage.getItem("loggerId")});
      this.socket.emit("offlineToggled", {...this.$store.getters.userData, logActivity: true, loggerId: sessionStorage.getItem("loggerId")});
      sessionStorage.removeItem('inCall');
    })

    window.onbeforeunload = async () => {
      if(!this.isMissedCall && !this.isCallNotPicked ) {
        this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: sessionStorage.getItem("loggerId")});
        sessionStorage.removeItem("loggerId");
        this.isMissedCall = false;
      }
      sessionStorage.removeItem('inCall');
      if(this.isCallNotPicked) {
        let { customerData, userId } = this.$store.getters.userData;
        this.socket.emit("activityChecker", {customerData, userId, activity: "Didn't Pick up the Call", loggerId: sessionStorage.getItem("loggerId")});

        this.isCallNotPicked = false;
      }
    };

    if (!this.$store.getters.userData || !sessionStorage.getItem('userId')) {
      if(this.$store.getters.envValues?.client == 'citi') {
        this.$router.push({ path: '/custom/citi/errorpage', query: { errCode: 401 }});
      } else {
        this.$router.push("/");
      }
    } else {
      this.getMySpeed();
      let customerData = this.$store.getters.userData.customerData;
      if (customerData && customerData.config && customerData.config.global && customerData.config.global.keepREOnline) {
        this.keepREOnline = customerData.config.global.keepREOnline;
      }
      if(customerData && customerData.config && customerData.config.global ) {
        this.isRingToneRequired = customerData.config?.global?.isRingToneRequired;
        if(this.isRingToneRequired && customerData.config?.global?.selectedRingTone && this.$notificationSound) {
          this.$notificationSound._src = require(`@/assets/${customerData.config.global.selectedRingTone}`);
        }
      }
      // refactor vuex
      // Getting speed the first time
      await this.getAgentData(this.$store.getters.userData.userId);

      // getting spped after an interval of 15mins (default)
      this.internetSpeedCheckTimer = setInterval( async() => {
        this.getMySpeed();
      }, this.speedCheckInterval * 60 * 1000)
      if (!this.agentData.phone) {
        //this.askPhoneNumber = true;
      }
      this.isVideoKYC = customerData.isVideoKYC
        ? customerData.isVideoKYC
        : false;
      this.isVideoConferenceGrant = customerData.isVideoConference
        ? customerData.isVideoConference
        : false;
      this.isScreenshare = customerData.isScreenshare || false;

      if(customerData.config.introConf.disableRightClick) {
        document.addEventListener('contextmenu', rightClickBlock);
        document.onkeydown = disableInspect;
      } else {
        document.removeEventListener('contextmenu', rightClickBlock);
        document.onkeydown = {}
      }

      if (!sessionStorage.getItem("cancelLogin") || sessionStorage.getItem("cancelLogin") == undefined) {
        this.workOnSockets();
      } else {
        sessionStorage.removeItem("cancelLogin");
      }
      
      //imageUrl
      if (customerData.config.introConf.imageLogo && customerData.config.introConf.imageLogo.includes("persist")) {
        let respImg = await downloadFile(customerData.config.introConf.imageLogo);
        if (respImg.status === 200) {
          this.logoImageUrl = `data:${respImg.headers["content-type"].split(";")[0]};base64, ${respImg.data.file}`;
        }
      } else {
        this.logoImageUrl =
          customerData.config.introConf.imageLogo ||
          require("@/assets/signzy.png");
      }
      //Check for grants
      //Only video kyc
      if (this.isVideoKYC && !this.isVideoConferenceGrant) {
        console.log("online Video KYC", this.isMobile);
        this.isMobile = true;
        if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          this.$router.replace({
            path: "/error/nosupport",
          });
        } else {
          if (window.location.href.includes("/dashboard/conference")) {
            this.stopVideoConference();
          } else {
            this.isVideoConference = false;
            this.items = sideBar.dashboard;
          }
        }
      }
      //Only VideoConference
      else if (!this.isVideoKYC && this.isVideoConferenceGrant) {
        this.isMobile = true;
        this.startVideoConference();
      }
      // Both Video Conference and Video KYC
      else if (this.isVideoKYC && this.isVideoConferenceGrant) {
        //https://localhost:8080/dashboard/conference/initiator/WOzaXRb0H66qQOo6z1tXlgK1MfJ3yApw9W15TFvRNH5cswZb346qLctoudwFreklaZuBek
        if (window.location.href.includes("/dashboard/conference/initiator")) {
          this.isVideoConference = true;
          this.items = sideBar.conference;

        } else if (
          window.location.href.includes("/dashboard/conference") ||
          this.isMobile
        ) {
          this.startVideoConference();
        }
      } else {
        this.$router.replace({
          path: "/error/noGrant",
        });
      }
      eventBus.$on("freezeAll", () => {
        console.log("blocking all");
        this.toogleSidebar();
        this.disableAll = true;
        if(this.logoutEmmitingInterval){
          this.idleTimeForReLogoutLogging = 0;
          window.clearInterval(this.logoutEmmitingInterval);
        }
      });
      eventBus.$on("unFreezeAll", () => {
        console.log("unblocking all");

        if(this.logoutEmmitingInterval){
          window.clearInterval(this.logoutEmmitingInterval);
          sessionStorage.removeItem('inCall');
          console.log("cleared logoutEmmitingInterval");
        }
        this.idleTimeForReLogoutLogging = 0;
        this.logoutEmmitingInterval = setInterval(this.timerIncreamentForReLogoutLogging, 60000 * this.intervalTime);
        console.log("again set logoutEmmitingInterval");
        if(this.keepREOnline) {
          this.toggleOnline('on', true, 'unFreezeAll fn call');
        }
        this.toogleSidebar();
        this.disableAll = false;
      });

      if (sessionStorage.videoCallDone == "true") {
        sessionStorage.videoCallDone = false;
      }
    } 


  },
  watch : {
    showCallComing : function() {
      try {

        if(this.isRingToneRequired) {
          if(!this.showCallComing ) {
             this.$notificationSound.pause();
          } else {
              this.$notificationSound.loop(true);
              this.$notificationSound.load();
              this.$notificationSound.play();
          }
        }
      } catch(err) {
        console.log(err);
      }
    }
  }
};
</script>
<style scoped>
.activeMenu {
  background: #d4d2d2;
  border-right: 4px solid #0184c4;
}
@media only screen and (max-width: 850px) {
  .speedDrawer {
    display: none;
  }
}

#speedbar {
  padding-top: 64px;
  margin-left: 10px;
  margin-top: 5px;
}
#speedbar #retrybtn p {
  color : rgba(0, 0, 0, 0.25);
  font-family: 'Arial' ;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform : none
}
#speedbarTitle {
  font-style: normal;
  font-size: 16px;
  font-weight:600;
  color: rgba(0, 0, 0, 0.65);
  font-family: 'Arial';
}

#retrybtn {
  padding: 0;
  background-color: white;
}


.good {
    filter: none;
    filter : invert(50%) sepia(14%) saturate(1527%) hue-rotate(100deg) brightness(120%) contrast(90%);
    margin: 0;
}

.average {
    filter: none;
    filter : invert(50%) sepia(89%) saturate(631%) hue-rotate(10deg) brightness(120%) contrast(100%);
    margin: 0;
    font-weight: 600;
}

.poor {
    filter: none;
    filter : invert(36%) sepia(59%) saturate(6582%) hue-rotate(348deg) brightness(112%) contrast(99%);
    margin: 0;
    font-weight: 600;
}

.loading {
    filter: none;
    filter : invert(37%) sepia(40%) saturate(2696%) hue-rotate(174deg) brightness(75%) contrast(101%);
    margin: 0;
}

.offline {
    filter : invert(0%) sepia(90%) saturate(5%) hue-rotate(297deg) brightness(30%) contrast(0%);
    margin: 0;
}

#speedbarValue {
  font-family: 'Arial';
  font-weight: 600;
  font-size: 24px;
}

#speedbarMsg {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
}


#speedbarSubtitle {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
}

</style>
